import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import Layout from '../../components/Layout';
import '../../assets/global.css';

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p className="richText">{ children }</p>,
  },
  /* eslint-disable no-shadow */
  renderText: (text) => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
};

const ResourceTemplate = function ResourceTemplate({ data: { contentfulResource: resource } }) {
  const {
    title, date, image, content,
  } = resource;
  return (
    <Layout>
      <main className="resource-page-container">
        <div className="resource-image-container">
          <img className="resource-image" src={image.file.url} alt={image.file.fileName} />
          <h1 className="resource-title">{ title }</h1>
        </div>
        <div className="resource-page-main">
          <p className="resource-date">{date}</p>
          <div className="resource-rich-text">
            {documentToReactComponents(JSON.parse(content.raw), options)}
          </div>
        </div>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query singleResourceQuery($id: String) {
    contentfulResource(id: {eq: $id}) {
      title
      image {
        file {
          url
        }
      }
      date(formatString: "MM/DD/YYYY")
      tags {
        name
      }
      content {
        raw
      }
    }
  }
`;

ResourceTemplate.propTypes = {
  data: PropTypes.objectOf({
    contentfulResource: PropTypes.objectOf({
      title: PropTypes.string,
      date: PropTypes.string,
    }),
  }),
};

ResourceTemplate.defaultProps = {
  data: null,
};

export default ResourceTemplate;
